import { default as React, FC, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { ScriptConfigHistoryUrlParams } from "./scriptconfig-history-utils";
import {
  ScriptConfigHistoryDocument
} from '$typings/graphql-codegen';
import { pureFormatDate } from '$lib/dateHelpers';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import {
  DeleteScriptConfigHistoryDocument,
} from '$typings/graphql-codegen';
import { getUserFeatures } from '../../config/sessionService';
import { Button, Card } from "@mui/material";
import Icon from "$components/icons/icon/icon.react";
import RequireConfirmation from "$components/require-confirmation/require-confirmation.react";

export interface IScriptConfigHistoryProps {
  params: ScriptConfigHistoryUrlParams;
}

const ScriptConfigHistory: FC<IScriptConfigHistoryProps> = ({ params }) => {
  const [scriptConfigHistoryIdToDelete, setScriptConfigHistoryIdToDelete] = useState<Number | null>(null);
  
  const { data } = useQuery(ScriptConfigHistoryDocument, {
    scriptConfigId : parseInt(params.id) 
  });

  const features = getUserFeatures();

  const deleteOnCancel = () => {
    setScriptConfigHistoryIdToDelete(null)
  }

  const deleteOnConfirm = async () => {
    const scriptConfigHistoryId = scriptConfigHistoryIdToDelete as Number // we are sure it is not null here because that is the requirement for showing the RequireConfirmation dialog
    const lastItemDeleted = data?.scriptConfigHistory.items.length == 1
    await mutate(
      DeleteScriptConfigHistoryDocument,
      { scriptConfigHistoryId },
      true,
      () => {
        if (lastItemDeleted) {
          window.history.back()
        }
      },
      undefined
    );
    setScriptConfigHistoryIdToDelete(null)    
  }

  const tableDeleteButtonClick = async (scriptConfigHistoryId: Number) => {
    setScriptConfigHistoryIdToDelete(scriptConfigHistoryId);
  }

  return (
    <Card sx={{margin: "1rem", padding: "1rem", maxWidth:"800px"}}>
      <Button variant="outlined" sx={{marginBottom: "1rem"}} onClick={() => window.history.back()}>
            Back
      </Button>
      <br/>
      <span>{data?.scriptConfigHistory.name} history</span>
      <TableContainer style={{marginTop: "1rem"}} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Modified</TableCell>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Download</TableCell>
                {features.scriptConfigs.delete && (
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Delete</TableCell>
                )}                                
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.scriptConfigHistory.items.map((row) => (
                  <TableRow key={row.scriptConfigHistoryId}>
                    <TableCell>{pureFormatDate(row.configChanged, true, '.')}</TableCell>
                    <TableCell>                      
                      <Button
                        variant="outlined"
                        onClick={() => downloadFileFromUrl(row.readUrl, row.fileName)}
                        >
                        <Icon name={'fa-download'} />
                      </Button>
                    </TableCell>
                    {features.scriptConfigs.delete && (
                      <TableCell>                      
                        <Button
                          variant="outlined"
                          onClick={() => tableDeleteButtonClick(row.scriptConfigHistoryId)}
                          >
                          <Icon name={'fa-trash'} />
                        </Button>
                      </TableCell>
                    )}                    
                  </TableRow>
                ))} 
            </TableBody>
          </Table>
        </TableContainer>

        {scriptConfigHistoryIdToDelete != null && (
          <RequireConfirmation onConfirm={deleteOnConfirm} onCancel={deleteOnCancel}>
              <span>Are you sure you want to delete this script config from the history ?</span>
          </RequireConfirmation>
        )}
    </Card>
  );
};

export default ScriptConfigHistory;
