import { ScriptConfigHistoryUrlParams } from './scriptconfig-history-utils';
import ScriptConfigHistory, { IScriptConfigHistoryProps } from './scriptconfig-history.react';
import { ReactWrapper } from '$pages/common/react-wrapper';

export class ScriptConfigHistoryPage extends ReactWrapper<
  IScriptConfigHistoryProps,
  ScriptConfigHistoryUrlParams
> {
  constructor() {
    super(ScriptConfigHistory, (params) => ({
        params,
    }));
  }
}